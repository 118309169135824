<template>
  <div class="health-report">
    <div class="health-report__title">
      <div class="title-row">
        <div class="item">
          <div class="label">姓名：</div>
          <div class="value">{{ formData.name }}</div>
        </div>
        <div class="item">
          <div class="label">性别：</div>
          <div class="value">{{ formData.sex }}</div>
        </div>
        <div class="item">
          <div class="label">年龄：</div>
          <div class="value">{{ formData.age }}</div>
        </div>
        <div class="item">
          <div class="label">手机号：</div>
          <div class="value">{{ formData.contract }}</div>
        </div>
        <div class="item">
          <div class="label">身份证号：</div>
          <div class="value">{{ formData.idNum }}</div>
        </div>
      </div>
      <div class="title-row">
        <div class="item">
          <div class="label">报告号：</div>
          <div class="value">{{ formData.reportNum }}</div>
        </div>
        <div class="item">
          <div class="label">体检点位：</div>
          <div class="value">{{ formData.checkPlace }}</div>
        </div>
        <div class="item">
          <div class="label">体检日期：</div>
          <div class="value">{{ formData.checkTime }}</div>
        </div>
      </div>
    </div>
    <div class="health-report__table">
      <el-table :data="tableData" :stripe="true" :header-cell-style="{ background: '#e0e4eb', color: '#333' }">
        <el-table-column align="center" prop="target" label="指标"></el-table-column>
        <el-table-column align="center" prop="result" label="本次结果"></el-table-column>
        <el-table-column align="center" prop="unit" label="单位"></el-table-column>
      </el-table>
    </div>
      <div slot="footer" class="dialog-footer">
        <el-button icon="fa fa-undo" @click="$router.go(-1)">返回</el-button>
      </div>
  </div>
</template>

<script>
export default {
  props: {
    location: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  computed: {
    formData() {
      return JSON.parse(this.$route.query.item)
    },
    tableData() {
      return [
        {
          target: '身高',
          result: this.formData.height,
          unit: 'cm',
        },
        {
          target: '体重',
          result: this.formData.weight,
          unit: 'kg',
        },
        {
          target: '体质指数	',
          result: this.formData.bodyMass,
          unit: '-',
        },
        {
          target: '脂肪率',
          result: this.formData.fatRate,
          unit: '%',
        },
        {
          target: '腰围	',
          result: this.formData.waistline,
          unit: 'cm',
        },
        {
          target: '臀围	',
          result: this.formData.hipline,
          unit: 'cm',
        },
        {
          target: '腰臀比	',
          result: this.formData.waistHipRate,
          unit: '%',
        },
        {
          target: '收缩压',
          result: this.formData.systolicPressure,
          unit: 'mmHg',
        },
        {
          target: '舒张压',
          result: this.formData.diastolicPressure,
          unit: 'mmHg',
        },
        {
          target: '脉搏',
          result: this.formData.pulse,
          unit: '次/分',
        },
        {
          target: '血糖',
          result: this.formData.glycemicIndex,
          unit: 'mmol/L',
        },
        {
          target: '尿酸（嘌呤）',
          result: this.formData.uricAcid,
          unit: 'mmol/L',
        },
        {
          target: '总胆固醇',
          result: this.formData.totalCholesterol,
          unit: 'mmol/L',
        },
        {
          target: '体温',
          result: this.formData.temperature,
          unit: '℃',
        },
        {
          target: '血氧',
          result: this.formData.bloodOxygen,
          unit: '%',
        },
        {
          target: '心电	',
          result: this.formData.ecg,
          unit: '次/分',
        },
      ]
    }
  },
  data() {
    return {
    }
  },
  mounted() {},
  methods: {},
}
</script>

<style lang="scss" scoped>
.dialog-footer{
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}
::v-deep .el-table {
  font-size: 16px;
  color: #333;
}
::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td {
  background-color: #e0e4eb;
}
::v-deep .el-table th.is-leaf,
::v-deep .el-table td {
  border-bottom: none;
}
.health-report {
  &__title {
    border-top: 1px dashed #b5b5b5;
    border-bottom: 1px dashed #b5b5b5;
    background-color: #f9f9f9;
    padding: 10px 30px;
    margin-bottom: 10px;
    .title-row {
      width: 100%;
      font-size: 16px;
      line-height: 35px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .item {
        .label {
          float: left;
          color: #333;
        }
        .value {
          float: left;
          color: #71c3fb;
        }
      }
    }
  }
}
</style>
